import { React, Suspense, lazy } from "react";
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify';

// Librairie de traduction
import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

// Constant components
import ConnectionStatus from "presentation/components/component/connexionStatus/connectionStatus";

// Redirect route importation
const Loader = lazy(() => import('presentation/pages/loader'))

// Principal route imporatation
const MainPage = lazy(() => import('./main'))

// Initialize translate
i18n
.use(Backend)
.use(LanguageDetector)
.use(initReactI18next)
.init({
  debug: true,
  fallbackLng: 'fr',
  backend: {
    loadPath: "/locales/{{lng}}/translation.json",
  },
});


const RoutesConfig = () => {
  return (
    <BrowserRouter>
        <Suspense fallback={<Loader />}>
            {/* Toastify initialize */}
            <ToastContainer
                position="top-right"
                theme="dark"
                newestOnTop={false}
                pauseOnHover={true}
                rtl={false}
            />

            {/* Constant components */}
            <ConnectionStatus />

            {/* Pages Routes */}
            <MainPage />
        
        </Suspense>
    </BrowserRouter>

  )
}

export default RoutesConfig
